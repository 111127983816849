export type Config = typeof config

const imageSearchBaseUrl =
  'https://contextualwebsearch-websearch-v1.p.rapidapi.com/api/Search/ImageSearchAPI'
const imageFallbackStrategies: string[] = []

export const config = {
  useFallbackImages: false,
  imageFallbackStrategies,

  imageSearchBaseUrl,
  unsplashBaseUrl: '',
  unsplashClientId: '',
  xRapidapiHost: '',
  xRapidapiKey: '',

  debugMode: false,
}

export default config
